import './ScannerViewer.css';

import React , {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';

import QrReader from 'react-qr-scanner'
import config from "../environment";

const apiServerUrl = config.apiServerUrl;

const Scanner = () => {
    const navigate = useNavigate();
    const [facing, setFacing] = useState('environment');
    const [devices, setDevices] = useState([])
    const [userDataUrl, setUserDataUrl] = useState(null);
    const [autoOpen, setAutoOpen] = useState(true)

    useEffect( () => {
        navigator.mediaDevices.enumerateDevices().then( devices => {
            const cams = devices
                .filter( device => device.kind === 'videoinput' )
                .map( device => {return {name: device.label, id: device.deviceId} })
            setDevices(cams)
        });
    }, [])

    const handleError = (error) => {
        console.log('handleError', error)
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }

    const handleScan = async (scanData) => {
        if (scanData && scanData !== "") {
            if (scanData.text.startsWith('healthbvital://')) {
                const vitalData = { content: scanData.text };
                navigate('/healthbvital', {state: vitalData} );

            } else {
                const different = userDataUrl !== scanData.text
                setUserDataUrl(scanData.text)
                if (different && autoOpen) {
                    openInNewTab(scanData.text);
                }
            }
        }
      };

    const handleAutoOpenCheckbox = (value) => {
        setAutoOpen(value)
    }

    const getShareByNumber = async (number) => {
        return fetch(apiServerUrl + '/api/share/view/by-number/' + number, {
            method: 'GET',
        })
            .then(response => {
                return response.json();
            })
            .catch(error => {
                console.error('ERROR: ', error);
            });
    }

    const handleOpenByNumber = async () => {
        const sharingNumber = document.getElementById('shared-number').value
        const sharedData = await getShareByNumber(sharingNumber)
        if (sharedData && sharedData.shareId) {
            const url = '/shareddata/' + sharedData.shareId;
            const different = userDataUrl !== url
            setUserDataUrl(url)
            if (different && autoOpen) {
                openInNewTab(url);
            }
        }
    }

    const previewStyle = {
        height: 400,
        width: 480,
    }

    const delay = 100

    return (
        <div className="scanner-viewer">
            {devices &&
                <div>
                    <select onChange={(e) => setFacing(e.target.value)}>
                        {devices.map( device =>
                            <option key={device.id} value={device.id}>{device.name}</option>
                        )}
                    </select>
                </div>
            }
            <div style={{marginTop: '10px', maxWidth: '480px'}}>
                Her kan du som behandler få tilgang til din pasients helseoversikt & sende behandlingsplan og anbefaling tilbake. 
            </div>
            <div>
                <QrReader
                    style={previewStyle}
                    delay={delay}
                    onError={handleError}
                    onScan={handleScan}
                    constraints={
                        {
                            video: {
                                /*facingMode: { exact: facing }*/
                                deviceId: facing
                            }
                        }
                    }
                />
            </div>
            <div>
                <span className='scanner-info-big'>I fysisk konsultasjon - skann pasientens HealthB QR kode</span>
            </div>

            <div className='scanner-info'>Nettlenke for pasientdata:</div>
            <div className='scanner-link'>
                <a target="_blank" href={userDataUrl}>{userDataUrl}</a>
                <input type="checkbox" checked={autoOpen} defaultValue={autoOpen} onChange={(event) => handleAutoOpenCheckbox(event.target.checked)}/>
                <span>åpne automatisk etter skanning</span>
            </div>

            <div className="shared-by-number">
                <span>I digital konsultasjon – be pasienten oppgi sin HealthB tallkode:</span>
                <input type="text" id="shared-number" />
                <button onClick={handleOpenByNumber}>Få tilgang</button>
            </div>
        </div>
    )
}

export default Scanner
