import { useState } from "react";
import config from '../environment';

const apiServerUrl = config.apiServerUrl;

const SendMessageForm = ({shareId}) => {
    const [messageType, setMessageType] = useState('note');
    const [messageText, setMessageText] = useState('');
    const [messageStatus, setMessageStatus] = useState(null)

    const [noteType, setNoteType] = useState('goal')
    const [noteTitle, setNoteTitle] = useState('')

    const formatMessageText = () => {
        if (messageType === 'note') {
            const note = {
                type: noteType,
                title: noteTitle,
                text: messageText
            }
            return JSON.stringify(note);
        }
        return messageText;
    }

    const sendMessage = () => {
        const content = formatMessageText();
        return fetch(apiServerUrl + '/api/share/message-to/' + shareId, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({type: messageType, content: content})
        })
        .then(response => {
            if (response.status >= 400) {
                setMessageStatus('Feil: melding ble ikke sendt til pasienten')
            } else {
                setMessageStatus('Medling sendt')
                setMessageText('')
                setMessageType('note')
                setNoteTitle('')
                setNoteType('goal')
                setTimeout(() => setMessageStatus(null), 3000)
            }
        })
        .catch(error => {
            console.error('ERROR: ', error);
            setMessageStatus('Feil: melding ble ikke sendt til pasienten')
        })
}

    return (
        <div className="message-to-patient">
            <div style={{fontStyle: 'italic', marginBottom: '10px', fontSize: 'larger'}}>Send Notat til pasienten</div>
            <div style={{fontStyle: 'italic', marginBottom: '10px', fontSize: 'normal'}}>
                Send din anbefaling, behandlingsplan, notat, epikrise eller huskeliste til pasientens Notater.
            </div>
            <div>
                {/* <div className="message-type" style={{display: 'none'}}>
                    <select onChange={(event) => setMessageType(event.target.value)} value={messageType}>
                        <option value="note" >Notat</option>
                        <option value="text" >Tekst</option>
                    </select>
                </div> */}
                {messageType === 'note' &&
                    <>
                    {/*<div className="message-note-type">
                        <select onChange={(event) => setNoteType(event.target.value)} value={noteType}>
                            <option value="goal" >Mine behandlingsmål</option>
                            <option value="registration" >Mine registreringer</option>
                            <option value="therapist" >Ta opp med behandler</option>
                        </select>
                    </div>*/}
                    <div className="message-content">
                        <div style={{marginBottom: '6px'}}>Tittel</div>
                        <input className="healthb-input-text" type="text" value={noteTitle} onInput={e => setNoteTitle(e.target.value)} />
                    </div>
                    </>
                }
                <div className="message-content">
                    <div style={{marginBottom: '6px'}}>Notat</div>
                    <textarea className="healthb-textarea-text" onChange={(event) => setMessageText(event.target.value)} value={messageText}></textarea>
                </div>
                <div className="message-send-status">
                    {messageStatus}
                </div>
                <div className="message-send-button">
                    <button className="healthb-primary-btn" onClick={() => sendMessage()}>
                        Send Notat til pasienten
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SendMessageForm;
