import './App.css';
import {
  BrowserRouter,
  Routes,
  Route } from "react-router-dom";
import DataViewer from './widgets/DataViewer';
import VitalDataViewer from './widgets/VitalDataViewer'
import Scanner from './widgets/Scanner';

function App() {
  return (
    <div className="App">
      <header className="app-header">
        <div className="app-header-top-logo">
          <a href="https://www.healthb.no/">
            <img src="https://static.wixstatic.com/media/e744c5_c1824574e53d4753ab59a1e4380eb9c8~mv2.png/v1/fill/w_618,h_148,al_c,lg_1,q_85,enc_auto/Bilde2.png" />
          </a>
        </div>
        <div class="app-header-middle"></div>
        <div className="top-menu">
          <div><a href="https://www.healthb.no/partner">Partner</a></div>
          <div><a href="https://www.healthb.no/kontakt">Kontakt oss</a></div>
        </div>
      </header>
      <div className="app-content">
        <BrowserRouter>
          <Routes>
            <Route path="/shareddata/:shareId" element={<DataViewer />} />
            <Route path="/" element={<Scanner />} />
            <Route path="/healthbvital" element={<VitalDataViewer />} />
          </Routes>
        </BrowserRouter>
      </div>
      <footer className="app-footer">
        <div className="app-footer-menu">
          <div>
            <div style={{maxWidth: '256px', marginLeft: 'auto', marginRight: 'auto'}}>
              <a href="http://www.zyberia.org/">ZYBERIA</a> is a Norwegian HealthTech company established in Trysil, with headquarters in Oslo.
            </div>
          </div>
          <div>
            <div>
              Read our privacy policy and terms of use
            </div>
            <div>
              <a href="https://www.healthb.no/personvernerklaering" style={{textDecoration: 'underline'}}>Personvernerklæring</a>
            </div>
            <div>
            <a href="https://www.healthb.no/brukervilkar" style={{textDecoration: 'underline'}}>Brukervilkår</a>
            </div>
          </div>
          <div>
            <div>
              <div>HealthB is developed by <a href="http://www.zyberia.org/">ZYBERIA</a></div>
              <div>Copyright Zyberia AS 2023 ©</div>
              <div>All rights reserved</div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
