import './VitalDataViewer.css';

import { useLocation, useNavigate } from 'react-router-dom';

const fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

const copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
    }, function(err) {
        alert('Could not copy text, error: ' + err);
        console.error('Async: Could not copy text: ', err);
    });
}

const VitalDataViewer = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    if (!state || !state.content) {
        return <div>Ukjent feil...</div>
    }

    const vitalContent = state.content.replace('healthbvital://', '')

    const vitalContentLines = vitalContent.split('\n')

    return (
        <div className="vital-data-viewer">
            <div className="vital-logo">
                <img src="https://healthb-vital-57736.web.app/assets/healthb-vital-primary.984504c1.svg" />
            </div>
            {vitalContentLines.map(line => 
                <div>{line}</div>
            )}

            <div>
                <button className="healthb-primary-btn" style={{marginTop: '40px'}} onClick={()=>copyTextToClipboard(vitalContent)}>
                    Kopier & lim inn i ditt pasientjournalsystem  
                </button>

                <button className="healthb-primary-btn" style={{marginTop: '40px', marginLeft: '4rem'}} onClick={()=>navigate('/')}>
                    Tilbake til skannevinduet  
                </button>
            </div>
        </div>
    )
};

export default VitalDataViewer;
