
export const getConfig = () => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
        return require('./config.prod').config;
    } else {
        return require('./config.dev').config;
    }
}

const config = getConfig();
export default config;
